import React from 'react'
import "./App.css";

import View from "./Views/View";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <View />
    </div>
  );
}

export default App;
