import React, { useState, useEffect } from "react";

import "./Main.css";

import flavours from "../../Fruits.json"
import sizes from "../../Sizes.json"


import Complete from '../Complete/Complete'


function getImage(name) {
    return `${process.env.PUBLIC_URL}/assets/${name}.png`
}

const Main = () => {
    const [flavour, setFlavour] = useState("Lemon");
    const [size, setSize] = useState("small");

    const [submit, setSubmit] = useState(false);
    useEffect(() => {
        console.log(flavour, ` `, size)
    }, [size, flavour])

    var show;
    if (submit) { show = "block" } else { show = "none" };

    return (
        <div className="Main">
            <div style={{ display: show }}>
                <Complete flavour={flavour} size={size} />
            </div>
            <div className="squared">
                <h1 id="Title">Lemonade Stand</h1>
                <div className="MainContent">
                    <div id="DrinkDiv">This is gonna be a glass</div>
                    <div id="Selectors">
                        <h2 className="MenuTitle">Sizes</h2>

                        <div className="ButtonFlex">
                            {sizes.map((size) => {
                                return (<button className="SelectButton" onClick={() => { setSize(size.name) }}>{size.name}</button>)
                            })}
                        </div>
                        <h2 className="MenuTitle">Flavours</h2>

                        <div className="ButtonFlex">
                            {flavours.map((flavour) => {
                                return (<button className="SelectButton" onClick={() => { setFlavour(flavour.name) }}><img src={getImage(flavour.name)} alt={flavour.name} /></button>)
                            })}
                        </div>
                    </div>
                </div>

                <button onClick={() => { setSubmit(true) }} id="SubmitButton">Submit!</button>
            </div>
        </div>
    )
}

export default Main
