import React, { useState, useEffect } from "react";

import "./Initial.css";

import Main from "../Main/Main"

const Initial = () => {
    /*C# equivalent to the following JS code
    public string menu
    {
        get { return menu; } <--- menu
        set { menu = value; } <--- setMenu
    }
    */
    const [menu, setMenu] = useState(false);

    if (menu) return (<Main />)

    return (
        <div id='Initial' className="squared">
            <h1 id="InitialCTA">welcome to <br /> big boolean's <br /> lemonade stand!</h1>
            <button id="StartButton" onClick={() => { setMenu(true) }}>Start!</button>
        </div>
    )
}

export default Initial
