import React from 'react'

const Complete = (order) => {
    return (
        <div>
            {order.flavour} {order.size}
        </div>
    )
}

export default Complete
