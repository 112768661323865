import React from 'react'
import Initial from './Initail/Initial'

const View = () => {
    return (
        <Initial />
    )
}

export default View
